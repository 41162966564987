<template>

  <div id="app">

    <app-header class="header">
    </app-header>

    <router-view class="content">
    </router-view>

    <app-footer class="footer">
    </app-footer>

  </div>

</template>

<script>
import appFooter from '@/components/footer'
import appHeader from '@/components/header'

export default {
  name: 'app',
  components: {
    appFooter,
    appHeader
  }
}
</script>

<style>
html,
body {
  margin: 0;
  min-width: 1280px;
  padding: 0;
}

ul,
li {
  list-style: none;
}

@font-face {
  font-family: 'DINProMedium';
  src: url('/font/DINPro-Medium.otf');
}

@font-face {
  font-family: 'PFSCR';
  src: url('/font/PingFangSCRegular.ttf');
}

#app {
  color: #000;
  background-color: rgba(240, 245, 251, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: 'PFSCR', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px;
  position: relative;
  text-align: center;
}

.header,
.footer {
  flex-shrink: 0;
}
.content {
  flex-grow: 1;
}

[role="content"] {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}

.primary {
  color: dodgerblue;
}

::-webkit-scrollbar {
  width : 5px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: dodgerblue;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2.5px rgba(0, 0, 0, 0.2);
  background-color: rgba(44, 53, 110, 0.5);
  border-radius: 5px;
}
</style>
