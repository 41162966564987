// 按需引入 Element
import Vue from 'vue'
import {
  Button,
  Carousel,
  CarouselItem,
  DatePicker,
  Dialog,
  Drawer,
  Divider,
  Form,
  FormItem,
  Icon,
  Image,
  Input,
  Message,
  Option,
  Pagination,
  Select,
  Table,
  TableColumn
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// 设置语言
locale.use(lang)

// 引入组件
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Drawer)
Vue.use(Divider)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Input)
Vue.use(Message)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Table)
Vue.use(TableColumn)

Vue.prototype.$message = Message
Message.closeAll()
