import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'homepage',
    meta: {
      title: 'HOME'
    },
    component: () => import('@/components/homepage/index.vue')
  },
  {
    path: '/buoy',
    name: 'buoy',
    meta: {
      title: 'BUOY'
    },
    component: () => import('@/components/metadata/buoy.vue')
  },
  {
    path: '/application-service',
    name: 'application-service',
    meta: {
      title: 'Application'
    },
    component: () => import('@/components/application/index.vue')
  },
  {
    path: '/application-service-detail',
    name: 'application-service-detail',
    meta: {
      parent: 'application-service',
      title: 'Application Detail'
    },
    component: () => import('@/components/application/detail.vue')
  },
  // 综合新闻
  {
    path: '/news',
    name: 'news',
    meta: {
      name: 'news',
      title: 'Comprehensive News'
    },
    component: () => import('@/components/news/index.vue')
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    meta: {
      parent: 'news',
      title: 'Comprehensive News Detail'
    },
    component: () => import('@/components/news/detail.vue')
  },
  // 通知公告
  {
    path: '/notice',
    name: 'notice',
    meta: {
      name: 'notice',
      title: 'Notice'
    },
    component: () => import('@/components/news/index.vue')
  },
  {
    path: '/notice-detail',
    name: 'news-detail',
    meta: {
      parent: 'notice',
      title: 'Notice Detail'
    },
    component: () => import('@/components/news/detail.vue')
  },
  // 工作动态
  {
    path: '/working',
    name: 'news',
    meta: {
      name: 'working',
      title: 'Working News'
    },
    component: () => import('@/components/news/index.vue')
  },
  // 国际动态International
  {
    path: '/international',
    name: 'international',
    meta: {
      name: 'international',
      title: 'International News'
    },
    component: () => import('@/components/news/index.vue')
  },
  {
    path: '/international-detail',
    name: 'news-detail',
    meta: {
      parent: 'international',
      title: 'International News Detail'
    },
    component: () => import('@/components/news/detail.vue')
  },
  {
    path: '/about-us',
    name: 'about-us-intro',
    meta: {
      title: 'Brief Introduction'
    },
    component: () => import('@/components/about-us/intro.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: {
      title: 'Contact'
    },
    component: () => import('@/components/about-us/contact-us.vue')
  },
  {
    path: '/data-sharing-info',
    name: 'data-sharing-info',
    meta: {
      title: 'Data Introduction'
    },
    component: () => import('@/components/data-sharing/info.vue')
  },
  // 元数据查询
  {
    path: '/data-products',
    name: 'data-products',
    meta: {
      title: 'Access Data'
    },
    component: () => import('@/components/data-sharing/data-products.vue')
  },
  {
    path: '/data/products-detail',
    name: 'app-metadata',
    meta: {
      parent: 'data-products',
      title: 'Access Data Detail'
    },
    component: () => import('@/components/data-sharing/detail.vue')
  },
  // 数据产品
  {
    path: '/metadata-special-list',
    name: 'metadata-special-list',
    meta: {
      title: 'Data Products'
    },
    component: () => import('@/components/metadata-special/list.vue')
  },
  // 数据产品详情
  {
    path: '/data/metadata-special-detail',
    name: 'metadata-special-detail',
    meta: {
      parent: 'metadata-special-list',
      title: 'Data Products Detail'
    },
    component: () => import('@/components/metadata-special/detail.vue')
  },
  {
    path: '/data-visualization',
    name: 'data-visualization',
    meta: {
      name: 'visualization',
      title: 'Data Visualization'
    },
    component: () => import('@/components/application/data.vue')
  },
  {
    path: '/artificial-intelligence',
    name: 'artificial-intelligence',
    meta: {
      name: 'ai',
      title: 'Artificial Intelligence'
    },
    component: () => import('@/components/application/data.vue')
  },
  {
    path: '/data-visualization/detail',
    name: 'data-visualization-detail',
    meta: {
      parent: 'data-visualization',
      title: 'Data Visualization Detail'
    },
    component: () => import('@/components/application/detail.vue')
  },
  {
    path: '/artificial-intelligence/detail',
    name: 'artificial-intelligence-detail',
    meta: {
      parent: 'artificial-intelligence',
      title: 'Artificial Intelligence Detail'
    },
    component: () => import('@/components/application/detail.vue')
  },
  {
    redirect: '',
    path: '**'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
