'use strict'

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const BASE_URL_MAP = {
//   development: 'http://192.168.2.202:8080/',
//   production: 'http://159.226.158.60:8080/'
// }

const config = {
  // baseURL: BASE_URL_MAP[process.env.NODE_ENV]
  baseURL: process.env.VUE_APP_BASE_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

Vue.prototype.$baseURL = config.baseURL

const _axios = axios.create(config)

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    if (!response.config.file) {
      const text = response.request.responseText
      if (text) {
        const transText = text.replace(/"([^"]+)":(\d{16,})/ig, '"$1":"$2"')
        response.data = JSON.parse(transText)
      }
    }
    // Do something with response data
    return response
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
