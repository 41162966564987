<template>

  <div
    class="app-header"
    :style="container">

    <nav :style="nav">

      <router-link
        class="nav-logo"
        to="/">

        <img
          :class="logo"
          alt="LOGO"
          :src="url">

      </router-link>

      <ul class="menu">

        <li v-for="(group, gid) in menu"
          class="menu-group"
          :key="gid">

          <router-link v-if="group.url"
            class="link"
            :to="group.url">{{ group.label }}
          </router-link>

          <span v-else>{{ group.label }}
          </span>

          <ul v-if="group.options"
            class="submenu"
            :style="calcStyle(group.width)">

            <li v-for="(item, index) in group.options"
              :key="index">

              <router-link
                class="link"
                :to="item.url">{{ item.label }}
              </router-link>

            </li>

          </ul>

        </li>

      </ul>

      <div
        class="nav-language"
        @click="handleLanguage">中文
      </div>

      <!--<el-select
        class="nav-language"
        size="mini"
        v-model="language.type"
        @change="handleLanguage">

        <el-option v-for="(item, i) in language.options"
          :key="i"
          :label="item.label"
          :value="item.value">
        </el-option>

      </el-select>-->

    </nav>

    <ul v-show="!homeMode"
      class="breadcrumb"
      role="content">

      <li v-for="(item, index) in breadcrumb"
        :key="index">

        <span v-if="item.active">{{ item.label }}
        </span>

        <router-link v-else
          :to="item.url">{{ item.label }}
        </router-link>

      </li>

    </ul>

  </div>

</template>

<script>
export default {
  name: 'app-header',
  data () {
    return {
      breadcrumb: [
        {
          label: 'Home',
          url: '/'
        }
      ],
      container: {
        position: 'absolute'
      },
      homeMode: true,
      language: {
        type: 'en',
        options: [
          {
            label: 'ENG',
            value: 'en'
          },
          {
            label: '中文',
            value: 'cn'
          }
        ]
      },
      logo: 'active',
      menu: [
        {
          label: 'Home',
          url: '/'
        },
        {
          label: 'Datasets',
          options: [
            {
              label: 'Data Introduction',
              url: '/data-sharing-info'
            },
            // {
            //   label: 'In situ observations',
            //   url: '/'
            // },
            // {
            //   label: 'Access Data',
            //   url: '/data-products'
            // },
            {
              label: 'Data Products',
              url: '/metadata-special-list'
            }
          ],
          width: 220
        },
        {
          label: 'Application',
          // url: '/application-service',
          options: [
            {
              label: 'Data Visualization',
              url: 'data-visualization'
            },
            {
              label: 'Artificial Intelligence',
              url: 'artificial-intelligence'
            }
          ],
          width: 220
        },
        {
          label: 'News',
          url: '/news'
          // options: [
          //   {
          //     label: 'Comprehensive News',
          //     url: '/news'
          //   },
          //   {
          //     label: 'Notice',
          //     url: '/notice'
          //   },
          //   // {
          //   //   label: 'Working News',
          //   //   url: '/working'
          //   // },
          //   {
          //     label: 'International News',
          //     url: '/international'
          //   }
          // ],
          // width: 220
          // url: '/news'
        },
        {
          label: 'About',
          options: [
            {
              label: 'Brief Introduction',
              url: '/about-us'
            },
            {
              label: 'Contact',
              url: '/contact-us'
            }
          ],
          width: 180
        }
      ],
      nav: {
        'background-color': 'rgba(9, 82, 184, 0)',
        'height': '120px',
        'left': '0px',
        'position': 'relative',
        'top': '0px'
      },
      url: '/img/index/logo-admin.png'
    }
  },
  watch: {
    $route: 'fetchData'
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    calcStyle (width) {
      return {
        width: `${width}px`
      }
    },
    fetchData () {
      const meta = this.$route.meta
      const name = this.$route.name
      const query = this.$route.query

      this.breadcrumb.splice(1)
      this.homeMode = name === 'homepage'
      this.nav['background-color'] = `rgba(2, 13, 58, ${this.homeMode ? 0 : 0.8})`
      this.nav.height = this.homeMode ? '120px' : '100px'
      // this.nav.position = this.homeMode ? 'relative' : 'fixed'
      this.nav.position = this.homeMode ? 'relative' : 'relative'
      this.container.position = this.homeMode ? 'absolute' : 'relative'
      this.url = this.homeMode ? '/img/index/logo-admin.png' : '/img/index/logo-admin-mini.png'
      this.logo = this.homeMode ? 'active' : ''

      if (meta.parent) {
        const routes = this.$router.options.routes
        const parent = routes.find((item) => item.name === meta.parent)
        if (parent) {
          this.breadcrumb.push({
            label: parent.meta.title,
            url: parent.path
          })
        }
      }

      this.breadcrumb.push({
        active: true,
        label: query.title || meta.title
      })
    },
    handleLanguage (e) {
      window.open('http://www.casodc.com', '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  height: 120px;
  right: 0px;
  top: 0px;
  width: 100vw;
  z-index: 9;

  nav {
    color: white;
    width: 100%;
  }
}
.link {
  color: white;
  font-size: 14px;
  text-decoration: none;
}

.nav-logo {
  float: left;
  margin: {
    left: 20px;
    //top: 10px;
  };
  text-decoration: none;

  img.active {
    height: 106px;
    margin: {
      top: 10px;
    };
    width: 745px;
  }

  img {
    height: 91px;
    width: 607px;
  }
}

.menu {
  display: flex;
  //height: 75px;
  letter-spacing: 1px;
  letter-spacing: 1px;
  //line-height: 100px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: {
    top: 27px;
  };
  padding: {
    left: 0px;
  };
  position: absolute;
  right: 130px;
  text-align: center;
  top: 0px;

  .menu-group {
    position: relative;
    * {
      font: {
        size: 15px;
      }
    }
  }
  .submenu {
    background-color: rgba(9, 82, 184, 1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    height: 0px;
    opacity: 0;
    padding: {
      bottom: 15px;
      left: 0px;
      top: 15px;
    };
    position: absolute;
    right: 20px;
    top: 100%;
    visibility: hidden;

    li {
      height: 32px;
      line-height: 32px;
    }
  }
  .menu-group:hover .submenu {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition-delay: .3s;
    transition-duration: .5s;
  }

  li {
    list-style: none;
    padding: {
      left: 20px;
      right: 20px;
    };
  }
}

.nav-language {
  border: 1px solid #FFFFFF;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  position: absolute;
  right: 10px;
  top: 23px;
  width: 70px;
}

.breadcrumb {
  font-weight: 600;
  margin-block-start: 30px;
  margin-block-end: 30px;
  margin: {
    //top: 125px;
  };
  padding: {
    left: 0px;
  };
  text-align: left;

  li {
    display: inline;
    list-style: none;
  }
  a {
    color: black;
    margin: {
      right: 40px;
    };
    position: relative;
    text-decoration: none;
  }
  a:after {
    content: '>';
    position: absolute;
    right: -20px;
  }
  span {
    color: dimgray;
  }
}
</style>
<style>
.nav-language .el-input__inner {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, .4);
  border-radius: 0;
  color: #FFFFFF;
}
</style>
