const Base64 = {
  // 加密
  encode (str) {
    return btoa(encodeURI(str))
  },
  // 解密
  decode (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURI(atob(str))
  }
}
export default Base64
