import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/axios'
import 'view-design/dist/styles/iview.css'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'
import './plugins/iview'
import './iconfont/iconfont.css'

import 'animate.css/animate.css'

// base64加密
import Base64 from './assets/base64.js'
Vue.prototype.$Base64 = Base64

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
