<template>

  <div class="app-footer">

    <!-- <div role="content">

      <div class="menu">

        <div v-for="(group, gid) in menu"
          class="menu-group"
          :key="gid">

          <h4>{{ group.label }}
          </h4>

          <ul class="submenu">

            <li v-for="(item, index) in group.options"
              :key="index">

              <router-link
                class="link"
                :to="item.url">{{ item.label }}
              </router-link>

            </li>

          </ul>

        </div>

      </div>

    </div> -->

    <div class="copy-right">

      <ul class="concat-us">

        <li v-for="(item, index) in contacts"
          :key="index">

          <img
            :alt="item.alt"
            :src="item.icon">

          <span>{{ item.text }}
          </span>

        </li>

      </ul>

      <span>Copyright &copy; 2002 - 2021 Institute of Oceanology Chinese Academy of Sciences
      </span>

    </div>

  </div>

</template>

<script>
export default {
  name: 'app-footer',
  data () {
    return {
      contacts: [
        {
          alt: 'FOOTER_PHONE',
          icon: '/img/ft-phone.png',
          text: 'Tel: 0532-82896760'
        },
        {
          alt: 'FOOTER_EMAIL',
          icon: '/img/ft-email.png',
          text: 'Email: sjzx@qdio.ac.cn'
        },
        {
          alt: 'FOOTER_ADDRESS',
          icon: '/img/ft-address.png',
          text: 'Adress: 7 Nanhai Road, Qingdao, Shandong Province'
        }
      ],
      menu: [
        {
          label: 'DATA SHARING',
          options: [
            {
              label: 'Introduction to data resources',
              url: '/'
            },
            {
              label: 'Global observation data',
              url: '/'
            },
            {
              label: 'Database directory',
              url: '/'
            },
            {
              label: 'Data Products',
              url: '/'
            }
          ]
        },
        {
          label: 'APPLICATION & SERVICE',
          options: [
            {
              label: 'Data visualization',
              url: '/application-service?type=visualization'
            },
            {
              label: 'Artificial intelligence',
              url: '/application-service?type=ai'
            }
          ]
        },
        {
          label: 'NEWSROOM',
          options: [
            {
              label: 'Comprehensive news',
              url: '/news'
            }
          ]
        },
        {
          label: 'ABOUT US',
          options: [
            {
              label: 'Introduction',
              url: '/about-us'
            },
            {
              label: 'Contact us',
              url: '/contact-us'
            }
          ]
        }
      ]
    }
  }
}

</script>

<style lang="scss" scoped>
$gap: 25px;

.app-footer {
  background-color: rgba(0, 43, 73, 1);
  color: white;
  flex-flow: 1;
}
.link {
  color: white;
  font-size: 14px;
  text-decoration: none;
}

.menu {
  display: flex;
  flex-direction: row;
  padding: {
    bottom: $gap;
    top: $gap;
  };
  text-align: left;

  .menu-group {
    flex-grow: 1;
  }
  .submenu {
    padding: {
      left: 20px;
    };

    li {
      line-height: 28px;
    }
  }
}

.copy-right {
  background-color: rgba(41, 44, 51, 1);
  box-sizing: border-box;
  height: 180px;
  padding: {
    top: 59px;
  };

  span {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
  }

  .concat-us {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: {
      bottom: 41px;
      left: auto;
      right: auto;
      top: 0;
    };
    padding: 0;
    width: 1200px;

    li {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    span {
      color: rgba(153, 153, 153, 1);
    }

    img {
      height: 45px;
      margin-right: 17px;
      width: 45px;
    }
  }
}
</style>
